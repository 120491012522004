export const BulkAssetsConfig = {
  selectAssetsOfInterest: 'Seleccione assets de interés',
  selectStatusDescription1:
    'Seleccionar el estado al que se quieren cambiar los assets seleccionados.',
  selectStatusDescription2: 'Este estado reemplazará su estado actual',
  unavailableStatusAlert:
    'Algunos de los assets seleccionados no tienen el estado indicado. Por favor, ir al final de la página para asignarles un estado.',
  selectAssetsByStatus:
    'Seleccionar todos los assets pertenecientes a uno o más estados.',
  selectOrDeselectAssetsIndividually:
    'Seleccionar o deseleccionar assets individualmente.',
  unavailableStatusDescription1:
    'Los siguientes assets no tienen disponible el estado ',
  unavailableStatusDescription2:
    ', por favor seleccione otro. Si no se selecciona otro estado, los assets continuarán sin cambios.',
  assetsWithoutStatus: 'Assets que no tienen disponible el estado ',
  selectedAssetsCount: (count: number, total: number) =>
    `${count} de ${total} assets seleccionados.`,
  maximum: 'máximo',
  areYouSureMessage: '¿Estás seguro de que deseas aplicar estos cambios?',
  theStatusWillChange: 'Los assets seleccionados cambiarán su estado.',
  maxSelectionReachedTitle: 'Máximo de assets seleccionados alcanzado',
  maxSelectionReachedDescription:
    'Se ha alcanzado la máxima cantidad de assets seleccionados',
};
