/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

export const useSocketIOStore = create<{
  isConnectionInitialised: boolean;
  startConnection: () => void;
  isSocketConnected: boolean;
  reconnectSocket: () => void;
  socket?: any;
  setSocket: (newSocket: any) => void;
}>((set) => {
  return {
    isConnectionInitialised: false,
    isSocketConnected: false,
    reconnectSocket: () => {
      const socket: any = useSocketIOStore.getState().socket;
      let interval;
      if (socket && !socket?.id && !interval) {
        interval = setInterval(() => {
          socket.disconnect();
          socket.connect();
        }, 1000);
      }
      if (interval && socket?.id) {
        clearInterval(interval);
        set({ isSocketConnected: true });
        interval = null;
      }
    },
    startConnection: () => set(() => ({ isConnectionInitialised: true })),
    socket: undefined,
    setSocket: (newSocket: any) =>
      set(() => ({ socket: newSocket, socketId: newSocket?.id })),
  };
});
