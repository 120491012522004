export const BulkAssetsConfig = {
  selectAssetsOfInterest: 'Select assets of interest',
  selectStatusDescription1: 'Choose the new status for the selected assets.',
  selectStatusDescription2: 'This will overwrite their current status',
  unavailableStatusAlert:
    'Some of the selected assets do not have the specified status. Please navigate to the bottom of the page to assign one.',
  selectAssetsByStatus: 'Choose all assets within one or more statuses.',
  selectOrDeselectAssetsIndividually: 'Select or deselect assets individually.',
  unavailableStatusDescription1: "The following assets don't have the ",
  unavailableStatusDescription2:
    ' status available. Please choose another status. If no other status is selected, the assets will stay unchanged.',
  assetsWithoutStatus: 'Assets that do not have the available status ',
  selectedAssetsCount: (count: number, total: number) =>
    `${count} out of ${total} assets selected.`,
  maximum: 'maximum',
  areYouSureMessage: 'Are you sure you want to apply these changes?',
  theStatusWillChange: 'The status of the selected assets will change.',
  maxSelectionReachedTitle: 'Maximum selected assets reached',
  maxSelectionReachedDescription:
    'The maximum number of selected assets has been reached',
};
