export const Status = {
  downloading: 'Descargando',
  downloadedM: 'descargado',
  downloadedFP: 'descargadas',
  adding: 'Agregando',
  addedF: 'agregada',
  creating: 'Creando',
  created: 'creado',
  createdF: 'creada',
  updating: 'Actualizando',
  updated: 'actualizado',
  updatedF: 'actualizada',
  updatedP: 'actualizados',
  deleting: 'Eliminando',
  deleted: 'eliminado',
  deletedF: 'eliminada',
  disconnected: 'desconectado',
  sorting: 'Ordenando',
  sorted: 'ordenado',
  sortedP: 'ordenados',
  sortedFP: 'ordenadas',
  linking: 'Asignando',
  linked: 'asignado',
  linkedP: 'asignados',
  unlinking: 'Desasignando',
  unlinked: 'desasignado',
  unlinkedP: 'desasignados',
  copying: 'Copiando',
  copied: 'copiado',
  copiedF: 'copiada',
  successfully: 'con éxito',
};
