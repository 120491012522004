export const Status = {
  downloading: 'Downloading',
  downloadedM: 'downloaded',
  downloadedFP: 'downloaded',
  adding: 'Adding',
  addedF: 'added',
  creating: 'Creating',
  created: 'created',
  createdF: 'created',
  updating: 'Updating',
  updated: 'updated',
  updatedF: 'updated',
  updatedP: 'updated',
  deleting: 'Deleting',
  deleted: 'deleted',
  deletedF: 'deleted',
  disconnected: 'disconnected',
  sorting: 'Sorting',
  sorted: 'sorted',
  sortedP: 'sorted',
  sortedFP: 'sorted',
  linking: 'Linking',
  linked: 'linked',
  linkedP: 'linked',
  unlinking: 'Unlinking',
  unlinked: 'unlinked',
  unlinkedP: 'unlinked',
  copying: 'Copying',
  copied: 'copied',
  copiedF: 'copied',
  successfully: 'successfully',
};
