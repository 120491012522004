/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import {
  RealtimeData,
  RealTimeStoreType,
  Subscription,
  WaitingTask,
} from '../../types/stores/RealtimeStore';

export const useRealTimeStore = create<RealTimeStoreType>((set) => {
  return {
    activeSubscriptions: [],
    addSubscription: (newSubscription) =>
      set(
        (state) => ({
          ...state,
          activeSubscriptions: updateSubscriptions(
            state.activeSubscriptions,
            newSubscription
          ),
        }),
        true as any
      ),
    removeSubscription: (removedSubscription) =>
      set(
        (state) => ({
          ...state,
          activeSubscriptions: removeSubscription(
            state.activeSubscriptions,
            removedSubscription
          ),
        }),
        true as any
      ),
    isSubscribed: (newSubscription) => {
      const activeSubscriptions: Subscription[] =
        useRealTimeStore.getState().activeSubscriptions;
      return isSubscribed(activeSubscriptions, newSubscription);
    },
    waitingTasks: [],
    clearTasks: () =>
      set((state) => ({ ...state, waitingTasks: [] }), true as any),
    addWaitingTask: (newTask) =>
      set(
        (state) => ({
          waitingTasks: addWaitingTask(state.waitingTasks, newTask),
        }),
        true as any
      ),
    removeWaitingTask: (removedTask) =>
      set(
        (state) => ({
          waitingTasks: removeWaitingTask(state.waitingTasks, removedTask),
        }),
        true as any
      ),
    lastData: {},
    getSensorData: (sensorId: string): RealtimeData => {
      const _lastData = useRealTimeStore.getState().lastData;
      return _lastData[sensorId];
    },
    mergedLastData: [],
    updateSensorLastData: (sensorId: string, newContent: RealtimeData) => {
      const _lastData = useRealTimeStore.getState().lastData;
      if (_lastData[sensorId] && _lastData[sensorId].id === newContent.id) {
        //console.log('omitido', new Date(Date.now()).toLocaleString());
        set((state) => ({
          ...state,
          lastTimeUpdated: new Date(Date.now()),
        }));
        return;
      } else {
        //console.log('actualizado', new Date(Date.now()).toLocaleString());
        set(
          (state) => ({
            ...state,
            lastData: updateSensorLastData(
              sensorId,
              newContent,
              state.lastData
            ),
            mergedLastData: getMergedData(
              updateSensorLastData(sensorId, newContent, state.lastData)
            ),
            lastTimeUpdated: new Date(Date.now()),
          }),
          true as any
        );
      }
    },
    removeLastDataEntry: (id: string) =>
      set(
        (state) => ({
          ...state,
          lastData: removeLastDataEntry(id, state.lastData),
        }),
        true as any
      ),
    lastTimeUpdated: new Date(),
  };

  function isSubscribed(
    activeSubscriptions: Subscription[],
    newSubscription: Subscription
  ): boolean {
    const index = activeSubscriptions.findIndex((current) => {
      return (
        current.entity.modelName === newSubscription.entity.modelName &&
        JSON.stringify(current.options.id) ===
          JSON.stringify(newSubscription.options.id)
      );
    });
    return index !== -1;
  }

  function updateSubscriptions(
    activeSubscriptions: Subscription[],
    newSubscription: Subscription
  ) {
    if (!activeSubscriptions || !newSubscription) {
      return activeSubscriptions;
    }
    const copyOfActiveSubscription = structuredClone(activeSubscriptions);
    const index = activeSubscriptions.findIndex((current) => {
      return (
        current.entity.modelName === newSubscription.entity.modelName &&
        JSON.stringify(current.options.id) ===
          JSON.stringify(newSubscription.options.id)
      );
    });

    if (index === -1) {
      copyOfActiveSubscription.push(newSubscription);
    } else {
      copyOfActiveSubscription[index] = newSubscription;
    }

    return copyOfActiveSubscription;
  }

  function removeSubscription(
    activeSubscriptions: Subscription[],
    removedSubscription: Subscription
  ) {
    if (!activeSubscriptions || !removedSubscription) {
      return activeSubscriptions;
    }
    const copyOfActiveSubscription = structuredClone(activeSubscriptions);
    const index = activeSubscriptions.findIndex((current) => {
      return (
        current.entity.modelName === removedSubscription.entity.modelName &&
        JSON.stringify(current.options.id) ===
          JSON.stringify(removedSubscription.options.id)
      );
    });

    if (index !== -1) {
      copyOfActiveSubscription.splice(index, 1);
    }

    return copyOfActiveSubscription;
  }

  function removeWaitingTask(
    waitingTasks: WaitingTask[],
    removedTask: WaitingTask
  ): WaitingTask[] {
    const {
      entity: { modelName },
      options: {
        channels: { id, socketId },
      },
    } = removedTask;
    const copyOfTasks = structuredClone(waitingTasks);
    const index = waitingTasks.findIndex(
      (task) =>
        task.entity.modelName === modelName &&
        task.options.channels.id === id &&
        task.options.channels.socketId === socketId
    );
    if (index === -1) {
      return waitingTasks;
    } else {
      copyOfTasks.splice(index, 1);
      return copyOfTasks;
    }
  }

  // Agregar función que ejecute las tareas pendientes

  function addWaitingTask(
    waitingTasks: WaitingTask[],
    newTask: WaitingTask
  ): WaitingTask[] {
    const {
      entity: { modelName },
      options: {
        channels: { id, socketId },
      },
    } = newTask;
    const copyOfTasks = structuredClone(waitingTasks);
    const index = waitingTasks.findIndex(
      (task) =>
        task.entity.modelName === modelName &&
        task.options.channels.id === id &&
        task.options.channels.socketId === socketId
    );
    if (index === -1) {
      return waitingTasks;
    }
    copyOfTasks.push(newTask);
    return copyOfTasks;
  }

  function removeLastDataEntry(
    id: string,
    lastData: Record<string, RealtimeData>
  ): Record<string, RealtimeData> {
    const _lastData = structuredClone(lastData);
    delete _lastData[id];
    return _lastData;
  }

  function updateSensorLastData(
    id: string,
    newContent: RealtimeData,
    lastData: Record<string, RealtimeData>
  ): Record<string, RealtimeData> {
    const _lastData = structuredClone(lastData);
    _lastData[id] = { ...newContent, sensorId: id };
    return _lastData;
  }

  function getMergedData(
    lastData: Record<string, RealtimeData>
  ): RealtimeData[] {
    return Object.keys(lastData)
      .map((key) => lastData[key])
      .flat();
  }
});
