/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Customer_subscribeAssets,
  Customer_subscribeDevices,
  Customer_unsubscribeAssets,
  Customer_unsubscribeDevices,
  Project_subscribe,
  Project_unsubscribe,
} from '@xompass/web-sdk';
import { chunkArray } from '../utils/arrayUtils';
import { useShallow } from 'zustand/react/shallow';
import { useSocketIOStore } from './stores/useSocketStore';
import { useRealTimeStore } from './stores/useRealtimeStore';
import { useAssetsStatusStore } from './stores/useAssetsStatusStore';

export const useSocketIO = () => {
  const realTimeStore = useRealTimeStore(useShallow((state) => state));
  const assetsStatusStore = useAssetsStatusStore(useShallow((state) => state));
  const socket = useSocketIOStore((state) => state).socket;
  const params = useParams();
  const customerId = params.customerId || '';
  const projectId = params.projectId || '';
  const socketIOStore = useSocketIOStore(
    useShallow((state) => {
      return {
        isSocketConnected: state.isSocketConnected,
        reconnectSocket: state.reconnectSocket,
      };
    })
  );

  function initialiseListeners() {
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', (e: ErrorEvent) => console.log(e));
  }

  async function onConnect() {
    if (!socketIOStore.isSocketConnected) {
      socketIOStore.reconnectSocket();
      return;
    }
    console.log('Socket connected (React)');
    realTimeStore?.waitingTasks?.forEach((config) => {
      config.options.channels.socketId = socket.id;
      config.task(config);
    });

    realTimeStore?.activeSubscriptions?.forEach((config) => {
      subscribe(config.entity, config.options);
    });

    realTimeStore?.clearTasks();
  }

  async function onDisconnect() {
    console.log('Socket disconnected (React)');
    realTimeStore.clearTasks();
  }

  useEffect(() => {
    if (socket && socket.connected && import.meta.env.VITE_RTPATH) {
      console.log('Socket is ready');
      startConnection();
    } else if (!socket || !import.meta.env.VITE_RTPATH) {
      console.log('Socket.IO not loaded');
      realTimeStore.clearTasks();
      return;
    }
  }, [socket]);

  async function startConnection() {
    if (projectId && socket.id) {
      await Project_subscribe(projectId, socket.id);
    }

    initEvents(socket);
  }

  function emit(event: any, params: any) {
    if (socket) {
      socket?.emit(event, params, (err: any) => {
        if (err) {
          console.log(event, err);
        }
      });
    }
  }

  function unsubscribeProject(channels: string[]) {
    for (const channel of channels) {
      socket?.off(channel);
    }
  }

  async function subscribeProject(channels: Record<string, boolean>) {
    try {
      if (!projectId || !socket.id) {
        console.log('Project or Socket not found');
        return;
      }
      await Project_subscribe(projectId, socket.id);
      socket.emit(
        'subscribe',
        {
          modelName: 'Project',
          modelId: projectId,
          channels: channels,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => console.log(e)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function execSubscribe(config: {
    entity: { modelName?: string };
    options: { id: string; channels: string[] };
  }) {
    let promise;

    const options: { id: string; socketId: string; where?: any } = {
      id: customerId,
      socketId: socket.id,
    };

    if (!config?.entity?.modelName) {
      return promise;
    }

    if (['Asset', 'Device'].indexOf(config.entity.modelName) !== -1) {
      options.where = {
        id: {
          inq: Array.isArray(config.options.id)
            ? config.options.id
            : [config.options.id],
        },
      };
    }

    switch (config.entity.modelName) {
      case 'Asset':
        promise = await Customer_subscribeAssets(
          customerId,
          socket.id,
          options?.where
        );
        break;
      case 'Device':
        promise = await Customer_subscribeDevices(customerId, socket.id);
        break;
      default:
        promise = Promise.resolve();
        break;
    }

    try {
      realTimeStore.addSubscription(config);
      const ids = Array.isArray(config.options.id)
        ? config.options.id
        : [config.options.id];
      ids.forEach((id: string) => {
        emit('subscribe', {
          modelName: config.entity.modelName,
          modelId: id,
          channels: config.options.channels || { data: true },
        });
      });
    } catch (err) {
      //getHTTPErrorMessage(err);
      console.log(err);
    }
    return promise;
  }

  async function parallelPromises(promises: any) {
    if (!Array.isArray(promises)) {
      return Promise.reject();
    }

    if (!promises.length) {
      return Promise.resolve();
    }

    const _promises: Promise<any>[] = [];
    promises.forEach(({ function: func, args }) => {
      promises.push(func(...args));
    });
    await Promise.allSettled(_promises);
    return;
  }

  async function execUnsubscribe(config: any) {
    let promise;
    const options: { id: string; socketId: string; where?: any } = {
      id: customerId,
      socketId: config.options.socketId,
    };

    let ids = [];
    if (['Asset', 'Device'].indexOf(config.entity.modelName) !== -1) {
      ids = Array.isArray(config.options.id)
        ? config.options.id
        : [config.options.id];
    }

    if (ids.length) {
      const chunks = chunkArray(ids, 100);
      let func;

      switch (config.entity.modelName) {
        case 'Asset':
          func = await Customer_unsubscribeAssets(
            customerId,
            options.where,
            socket.id
          );
          break;
        case 'Device':
          func = await Customer_unsubscribeDevices(
            customerId,
            options.where,
            socket.id
          );
          break;
        default:
          break;
      }

      const requests = [];
      for (const chunk of chunks) {
        const params = structuredClone(options);
        params.where = { id: { inq: chunk } };
        requests.push({ function: func, args: [params] });
      }

      promise = parallelPromises(requests);
    } else {
      // Loop infinito
      //promise = unsubscribe(config.entity, options);
      promise = Promise.resolve();
    }
    promise
      .then(() => {
        realTimeStore.removeSubscription(config);
      })
      .catch((err: any) => {
        //err = getHTTPErrorMessage(err);
        console.log(err);
      });
  }

  function subscribe(entity: any, options: any): Promise<any> {
    if (!options || typeof options !== 'object') {
      return Promise.resolve();
    }
    if (!socket?.id) {
      console.log('Connection failed, socket not found');
      return Promise.resolve();
    }
    if (!socket?.connected) {
      realTimeStore.addWaitingTask({
        entity: entity,
        options: options,
        task: execSubscribe,
      });
      return Promise.resolve();
    } else {
      options.socketId = socket.id;
      return execSubscribe({ entity: entity.entity, options: options });
    }
  }

  function unsubscribe(entity: any, options: any): Promise<any> {
    if (!options || typeof options !== 'object' || !entity.modelName) {
      return Promise.resolve();
    }

    if (!socket?.connected) {
      realTimeStore.addWaitingTask({
        entity: entity,
        options: options,
        task: execUnsubscribe,
      });
      return Promise.resolve();
    } else {
      options.socketId = socket?.id;
      return execUnsubscribe({ entity: entity, options: options });
    }
  }

  function initEvents(socket: any) {
    /**
     * updates
     */
    socket?.on('Asset.change', onGeneralMessage);
    socket?.on('Asset.container.update', onGeneralMessage);
    socket?.on('Asset.healthStatus', onGeneralMessage);
    socket?.on('Project.assets.healthStatus', (event: any) => {
      const { body } = event;
      const assetId = Object.keys(body).at(0) as string;
      assetsStatusStore.updateAssetsStatus(assetId, {
        currentHealthStatus: body[assetId].currentHealthStatus,
        lastCheck: body[assetId].currentHealthStatusDetails.requested.lastCheck,
      });

      onGeneralMessage();
    });

    /**
     * Data
     */
    socket?.on('customer.data', onData);
    socket?.on('asset.data', onData);
    socket?.on('sensor.data', onData);

    /**
     * Summaries
     */
    socket?.on('asset.summaries', onData);
    socket?.on('sensor.summaries', onData);

    /**
     * Alerts
     */
    socket?.on('sensor.alerts', onGeneralMessage);

    /**
     * UptimeCollector Create
     */
    socket?.on(
      'Customer.projects.assets.sensors.uptimeCollectors.create',
      onUptimeCollector
    );
    socket?.on(
      'Project.assets.sensors.uptimeCollectors.create',
      onUptimeCollector
    );
    socket?.on('Asset.sensors.uptimeCollectors.create', onUptimeCollector);

    /**
     * Events Create
     */
    socket?.on('Customer.events.create', onAssetEvent);
    socket?.on('Asset.events.create', onAssetEvent);
    socket?.on('EventTrigger.events.create', onAssetEvent);

    /**
     * Events Update
     */
    socket?.on('Customer.events.update', onAssetEvent);
    socket?.on('Asset.events.update', onAssetEvent);
    socket?.on('EventTrigger.events.update', onAssetEvent);

    /**
     * Events Data Create
     */
    socket?.on('Customer.events.data.create', onAssetEvent);
    socket?.on('Asset.events.data.create', onAssetEvent);
    socket?.on('EventTrigger.events.data.create', onAssetEvent);

    /**
     * Events Data Upload
     */
    socket?.on('Customer.events.data.upload', onAssetEvent);
    socket?.on('Asset.events.data.upload', onAssetEvent);
    socket?.on('EventTrigger.events.data.upload', onAssetEvent);

    /**
     * Events StateChanges create
     */
    socket?.on('Customer.events.stateChanges.create', onAssetEvent);
    socket?.on('Asset.events.stateChanges.create', onAssetEvent);
    socket?.on('EventTrigger.events.stateChanges.create', onAssetEvent);

    /**
     * Events StateChanges update
     */
    socket?.on('Customer.events.stateChanges.update', onAssetEvent);
    socket?.on('Asset.events.stateChanges.update', onAssetEvent);
    socket?.on('EventTrigger.events.stateChanges.update', onAssetEvent);

    /**
     * Events comment
     */
    socket?.on('Customer.events.comments.create', onAssetEvent);
    socket?.on('Asset.events.comments.create', onAssetEvent);
    socket?.on('EventTrigger.events.comments.create', onAssetEvent);

    /**
     * Device Events Create
     */
    socket?.on('Customer.devices.events.create', onDeviceEvent);
    socket?.on('Device.events.create', onDeviceEvent);

    /**
     * Device Events Update
     */
    socket?.on('Customer.devices.events.update', onDeviceEvent);
    socket?.on('Device.events.update', onDeviceEvent);

    /**
     * Device Events Data Create
     */
    socket?.on('Customer.devices.events.data.create', onDeviceEvent);
    socket?.on('Device.events.data.create', onDeviceEvent);

    /**
     * Device Events StateChanges create
     */
    socket?.on('Customer.devices.events.stateChanges.create', onDeviceEvent);
    socket?.on('Device.events.stateChanges.create', onDeviceEvent);

    /**
     * Device Events StateChanges update
     */
    socket?.on('Customer.devices.events.stateChanges.update', onDeviceEvent);
    socket?.on('Device.events.stateChanges.update', onDeviceEvent);

    /**
     * Device Events comment
     */
    socket?.on('Customer.devices.comments.create', onDeviceEvent);
    socket?.on('Device.events.comments.create', onDeviceEvent);

    /**
     * Healthcheck Events Create
     */
    socket?.on('Customer.healthcheckEvents.create', onHealthcheckEvent);
    socket?.on('Project.healthcheckEvents.create', onHealthcheckEvent);
    socket?.on('Asset.healthcheckEvents.create', onHealthcheckEvent);

    /**
     * Healthcheck Events Update
     */
    socket?.on('Customer.healthcheckEvents.update', onHealthcheckEvent);
    socket?.on('Project.healthcheckEvents.update', onHealthcheckEvent);
    socket?.on('Asset.healthcheckEvents.update', onHealthcheckEvent);

    /**
     * Healthcheck Events StateChanges create
     */
    socket?.on(
      'Customer.healthcheckEvents.stateChanges.create',
      onHealthcheckEvent
    );
    socket?.on(
      'Project.healthcheckEvents.stateChanges.create',
      onHealthcheckEvent
    );
    socket?.on(
      'Asset.healthcheckEvents.stateChanges.create',
      onHealthcheckEvent
    );

    /**
     * Healthcheck Events StateChanges update
     */
    socket?.on(
      'Customer.healthcheckEvents.stateChanges.update',
      onHealthcheckEvent
    );
    socket?.on(
      'Project.healthcheckEvents.stateChanges.update',
      onHealthcheckEvent
    );
    socket?.on(
      'Asset.healthcheckEvents.stateChanges.update',
      onHealthcheckEvent
    );

    /**
     * Healthcheck Events comment
     */
    socket?.on(
      'Customer.healthcheckEvents.comments.create',
      onHealthcheckEvent
    );
    socket?.on('Project.healthcheckEvents.comments.create', onHealthcheckEvent);
    socket?.on('Asset.healthcheckEvents.comments.create', onHealthcheckEvent);
  }

  function onHealthcheckEvent(data: any) {
    switch (data.type) {
      case 'Customer.healthcheckEvents.create':
      case 'Customer.healthcheckEvents.update':
      case 'Customer.healthcheckEvents.comments.create':
      case 'Customer.healthcheckEvents.stateChanges.create':
      case 'Customer.healthcheckEvents.stateChanges.update':
        onGeneralMessage();
        break;
      case 'Project.healthcheckEvents.create':
      case 'Project.healthcheckEvents.update':
      case 'Project.healthcheckEvents.comments.create':
      case 'Project.healthcheckEvents.stateChanges.create':
      case 'Project.healthcheckEvents.stateChanges.update':
        onGeneralMessage();
        break;
      case 'Asset.healthcheckEvents.create':
      case 'Asset.healthcheckEvents.update':
      case 'Asset.healthcheckEvents.comments.create':
      case 'Asset.healthcheckEvents.stateChanges.create':
      case 'Asset.healthcheckEvents.stateChanges.update':
        onGeneralMessage();
        break;
      default:
      //console.log('onHealthCheckEvent default', data);
    }
  }

  function onGeneralMessage(/*event: any*/) {
    //console.log(event.type);
    //realTimeStore.saveEvent({ key: event.type, value: event });
  }

  function onData(data: any) {
    //console.log('data', data);
    //console.log(new Date().toLocaleString('es', { timeZoneName: 'short' }));
    switch (data.type) {
      case 'customer.data':
      case 'customer.summaries':
        for (const assetId in data.body) {
          for (const sensorId in data.body[assetId]) {
            //console.log('customer summaries');
            broadcastData(data.body[assetId][sensorId], sensorId);
          }
        }
        break;
      case 'asset.data':
      case 'asset.summaries':
        for (const sensorId in data.body) {
          //console.log('asset summaries');
          broadcastData(data.body[sensorId], sensorId);
        }
        break;
      case 'sensor.data':
      case 'sensor.summaries': {
        const content = data.body;
        const sensorId = data.from.id;
        //console.log('sensor summaries');
        broadcastData(content, sensorId);
        break;
      }
      default:
      //console.log('onData', data);
    }
  }

  function broadcastData(data: any, sensorId: any) {
    //console.log('broadcast data', data, 'sensorId', sensorId);
    realTimeStore.updateSensorLastData(sensorId, {
      ...(data[0] ? data[0] : data),
      sensorId: sensorId,
    });

    onGeneralMessage();
  }

  function onAssetEvent(data: any) {
    switch (data.type) {
      case 'Customer.events.create':
      case 'Customer.events.update':
      case 'Customer.events.comments.create':
      case 'Customer.events.data.create':
      case 'Customer.events.data.upload':
      case 'Customer.events.stateChanges.create':
      case 'Customer.events.stateChanges.update':
        onGeneralMessage();
        break;
      case 'Asset.events.create':
      case 'Asset.events.update':
      case 'Asset.events.comments.create':
      case 'Asset.events.data.create':
      case 'Asset.events.data.upload':
      case 'Asset.events.stateChanges.create':
      case 'Asset.events.stateChanges.update':
        onGeneralMessage();
        break;
      case 'EventTrigger.events.create':
      case 'EventTrigger.events.update':
      case 'EventTrigger.events.comments.create':
      case 'EventTrigger.events.data.create':
      case 'EventTrigger.events.data.upload':
      case 'EventTrigger.events.stateChanges.create':
      case 'EventTrigger.events.stateChanges.update':
        onGeneralMessage();
        break;
      default:
      //console.log('onAssetEvent default', data);
    }
  }

  function onUptimeCollector(data: any) {
    //const event = 'io:sensors.uptimeCollectors.create';
    switch (data.type) {
      case 'Customer.projects.assets.sensors.uptimeCollectors.create': {
        onGeneralMessage();
        break;
      }
      case 'Project.assets.sensors.uptimeCollectors.create': {
        onGeneralMessage();
        break;
      }

      case 'Asset.sensors.uptimeCollectors.create': {
        onGeneralMessage();
        break;
      }

      default:
      //console.log('onUptimeCollector default', data);
    }
  }

  function onDeviceEvent(data: any) {
    switch (data.type) {
      case 'Customer.devices.events.create':
      case 'Customer.devices.events.update':
      case 'Customer.devices.events.comments.create':
      case 'Customer.devices.events.stateChanges.create':
      case 'Customer.devices.events.stateChanges.update': {
        onGeneralMessage();
        break;
      }
      case 'Device.events.create':
      case 'Device.events.update':
      case 'Device.events.comments.create':
      case 'Device.events.stateChanges.create':
      case 'Device.events.stateChanges.update': {
        onGeneralMessage();
        break;
      }

      case 'Device.events.data.create':
      case 'Device.events.data.upload': {
        onGeneralMessage();
        break;
      }

      default:
      //console.log('onDeviceEvent default', data);
    }
  }

  async function stopConnection() {
    if (projectId && socket.id) {
      Project_unsubscribe(projectId, socket.id);
    }
    socket?.off('connect');
    socket?.off('disconnect');
    socket?.off('connect_error');
    socket?.off('Asset.change');
    socket?.off('Asset.container.update');
    socket?.off('Asset.healthStatus');
    socket?.off('Project.assets.healthStatus');

    /**
     * Data
     */
    socket?.off('customer.data');
    socket?.off('asset.data');
    socket?.off('sensor.data');

    /**
     * Summaries
     */
    socket?.off('asset.summaries');
    socket?.off('sensor.summaries');

    /**
     * Alerts
     */
    socket?.off('sensor.alerts');

    /**
     * UptimeCollector Create
     */
    socket?.off('Customer.projects.assets.sensors.uptimeCollectors.create');
    socket?.off('Project.assets.sensors.uptimeCollectors.create');
    socket?.off('Asset.sensors.uptimeCollectors.create');

    /**
     * Events Create
     */
    socket?.off('Customer.events.create');
    socket?.off('Asset.events.create');
    socket?.off('EventTrigger.events.create');

    /**
     * Events Update
     */
    socket?.off('Customer.events.update');
    socket?.off('Asset.events.update');
    socket?.off('EventTrigger.events.update');

    /**
     * Events Data Create
     */
    socket?.off('Customer.events.data.create');
    socket?.off('Asset.events.data.create');
    socket?.off('EventTrigger.events.data.create');

    /**
     * Events Data Upload
     */
    socket?.off('Customer.events.data.upload');
    socket?.off('Asset.events.data.upload');
    socket?.off('EventTrigger.events.data.upload');

    /**
     * Events StateChanges create
     */
    socket?.off('Customer.events.stateChanges.create');
    socket?.off('Asset.events.stateChanges.create');
    socket?.off('EventTrigger.events.stateChanges.create');

    /**
     * Events StateChanges update
     */
    socket?.off('Customer.events.stateChanges.update');
    socket?.off('Asset.events.stateChanges.update');
    socket?.off('EventTrigger.events.stateChanges.update');

    /**
     * Events comment
     */
    socket?.off('Customer.events.comments.create');
    socket?.off('Asset.events.comments.create');
    socket?.off('EventTrigger.events.comments.create');

    /**
     * Device Events Create
     */
    socket?.off('Customer.devices.events.create');
    socket?.off('Device.events.create');

    /**
     * Device Events Update
     */
    socket?.off('Customer.devices.events.update');
    socket?.off('Device.events.update');

    /**
     * Device Events Data Create
     */
    socket?.off('Customer.devices.events.data.create');
    socket?.off('Device.events.data.create');

    /**
     * Device Events StateChanges create
     */
    socket?.off('Customer.devices.events.stateChanges.create');
    socket?.off('Device.events.stateChanges.create');

    /**
     * Device Events StateChanges update
     */
    socket?.off('Customer.devices.events.stateChanges.update');
    socket?.off('Device.events.stateChanges.update');

    /**
     * Device Events comment
     */
    socket?.off('Customer.devices.comments.create');
    socket?.off('Device.events.comments.create');

    /**
     * Healthcheck Events Create
     */
    socket?.off('Customer.healthcheckEvents.create');
    socket?.off('Project.healthcheckEvents.create');
    socket?.off('Asset.healthcheckEvents.create');

    /**
     * Healthcheck Events Update
     */
    socket?.off('Customer.healthcheckEvents.update');
    socket?.off('Project.healthcheckEvents.update');
    socket?.off('Asset.healthcheckEvents.update');

    /**
     * Healthcheck Events StateChanges create
     */
    socket?.off('Customer.healthcheckEvents.stateChanges.create');
    socket?.off('Project.healthcheckEvents.stateChanges.create');
    socket?.off('Asset.healthcheckEvents.stateChanges.create');

    /**
     * Healthcheck Events StateChanges update
     */
    socket?.off('Customer.healthcheckEvents.stateChanges.update');
    socket?.off('Project.healthcheckEvents.stateChanges.update');
    socket?.off('Asset.healthcheckEvents.stateChanges.update');

    /**
     * Healthcheck Events comment
     */
    socket?.off('Customer.healthcheckEvents.comments.create');
    socket?.off('Project.healthcheckEvents.comments.create');
    socket?.off('Asset.healthcheckEvents.comments.create');
    socket?.disconnect();
  }

  return {
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    stopConnection: stopConnection,
    subscribeProject: subscribeProject,
    unsubscribeProject: unsubscribeProject,
    initialiseListeners: initialiseListeners,
  };
};
