/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useEffect } from 'react';
import { getSocket } from '../utils/socket.io/socketIOUtils';
import { useSocketIO } from '../hooks/useSocketIO';
import { useSocketIOStore } from '../hooks/stores/useSocketStore';
import { useShallow } from 'zustand/react/shallow';

export const WebsocketContext = createContext<null>(null);

type WebSockerProvider = {
  children: ReactNode;
};

export const WebsocketProvider = ({ children }: WebSockerProvider) => {
  const socketIOStore = useSocketIOStore(
    useShallow((state) => {
      return {
        setSocket: state.setSocket,
        isSocketConnected: state.isSocketConnected,
        startConnection: state.startConnection,
        isConnectionInitialised: state.isConnectionInitialised,
      };
    })
  );
  const socketIO = useSocketIO();

  useEffect(() => {
    if (!import.meta.env.VITE_RTPATH) {
      console.log('RTPATH not defined');
      return;
    }
    if (
      !socketIOStore.isConnectionInitialised ||
      !socketIOStore.isSocketConnected
    ) {
      console.log('Connecting socket...');
      socketIOStore.setSocket(getSocket());
      // sets isConnectionInitialised to true
      socketIOStore.startConnection();
    } else if (
      socketIOStore.isConnectionInitialised &&
      socketIOStore.isSocketConnected
    ) {
      socketIO.initialiseListeners();
    }

    return () => {
      if (socketIOStore.isSocketConnected) {
        socketIO.stopConnection();
      }
    };
  }, [socketIOStore.isSocketConnected]);

  return (
    <WebsocketContext.Provider value={null}>
      {children}
    </WebsocketContext.Provider>
  );
};
