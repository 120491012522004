/**
 * Split an array into chunks.
 */
export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, chunkSize + i));
  }

  return chunks;
}

export type ObjectWithCreated = {
  created?: Date;
};

/**
 * Insert items into a sorted array by created date.
 */
export function insertIntoSortedArrayByCreated<T extends ObjectWithCreated>(
  array: T[],
  items: T | T[],
  order: 'ASC' | 'DESC' = 'ASC',
  inline = true
): T[] {
  if (!Array.isArray(items)) {
    items = [items];
  }

  if (!inline) {
    array = [...array];
  }

  for (const item of items) {
    if (!item.created) {
      throw new Error('item.created is undefined');
    }

    let startIndex = 0;
    let endIndex = array.length - 1;

    while (startIndex <= endIndex) {
      const middleIndex = Math.floor((startIndex + endIndex) / 2);
      const currentCreated = array[middleIndex].created || new Date(0);

      if (order === 'ASC') {
        if (currentCreated < item.created) {
          startIndex = middleIndex + 1;
        } else {
          endIndex = middleIndex - 1;
        }
      } else {
        if (currentCreated > item.created) {
          startIndex = middleIndex + 1;
        } else {
          endIndex = middleIndex - 1;
        }
      }
    }

    array.splice(startIndex, 0, item);
  }

  return array;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIfDuplicateExists(arr: any) {
  return new Set(arr).size !== arr.length;
}
