/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

type AssetStatus = { currentHealthStatus: string; lastCheck: Date };

type AssetsStatusStore = {
  assetsStatus: Record<string, AssetStatus>;
  lastChange: { id?: string; content?: AssetStatus };
  updateAssetsStatus: (assetId: string, newStatus: AssetStatus) => void;
};

export const useAssetsStatusStore = create<AssetsStatusStore>((set) => {
  return {
    assetsStatus: {},
    lastChange: {},
    updateAssetsStatus: (assetId: string, newStatus: AssetStatus) => {
      const _assetsStatus = useAssetsStatusStore.getState().assetsStatus;
      const currentStatus = _assetsStatus[assetId];
      const isDifferentStatus =
        !currentStatus ||
        (currentStatus?.currentHealthStatus &&
          newStatus?.currentHealthStatus &&
          currentStatus.currentHealthStatus !== newStatus.currentHealthStatus);
      if (
        isDifferentStatus ||
        new Date(currentStatus.lastCheck).getTime() !==
          new Date(newStatus.lastCheck).getTime()
      ) {
        const recordExtension: Record<string, AssetStatus> = {};
        recordExtension[assetId] = newStatus;

        set((state) => ({
          ...state,
          assetsStatus: { ..._assetsStatus, ...recordExtension },
        }));
      }
      if (isDifferentStatus) {
        set((state) => ({
          ...state,
          lastChange: { id: assetId, content: newStatus },
        }));
      }
    },
  };
});
