import { getLocalStorageValue } from '@xompass/web-sdk';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const io = require('socket.io-client/dist/socket.io');

export const getSocket = () => {
  const socket = io(
    import.meta.env.VITE_RTPATH +
      '?access_token=' +
      getLocalStorageValue('vsaas$accessToken'),
    {
      //autoConnect: false,
      transports: ['websocket'],
      reconnectionDelay: 3000,
    }
  );
  return socket;
};
